module.exports = function boolval(mixedVar) {
  // original by: Will Rowe
  //   example 1: boolval(true)
  //   returns 1: true
  //   example 2: boolval(false)
  //   returns 2: false
  //   example 3: boolval(0)
  //   returns 3: false
  //   example 4: boolval(0.0)
  //   returns 4: false
  //   example 5: boolval('')
  //   returns 5: false
  //   example 6: boolval('0')
  //   returns 6: false
  //   example 7: boolval([])
  //   returns 7: false
  //   example 8: boolval('')
  //   returns 8: false
  //   example 9: boolval(null)
  //   returns 9: false
  //   example 10: boolval(undefined)
  //   returns 10: false
  //   example 11: boolval('true')
  //   returns 11: true

  if (mixedVar === false) {
    return false
  }

  if (mixedVar === 0 || mixedVar === 0.0) {
    return false
  }

  if (mixedVar === '' || mixedVar === '0') {
    return false
  }

  if (Array.isArray(mixedVar) && mixedVar.length === 0) {
    return false
  }

  if (mixedVar === null || mixedVar === undefined) {
    return false
  }

  return true
}
